import axios from "axios";
import apiBaseURLs from "./apiEndpointURLs"; // Import the API base URLs
import i18n from "../locale/i18n";
import { store } from "../state/store";

const { apiBaseUrl } = apiBaseURLs;

axios.interceptors.request.use((request) => {
  request.headers["Accept-Language"] = i18n.language;
  const { header } = store.getState();
  if (header) {
    request.headers["Authorization"] = header;
  }
  return request;
});

export const signUp = (body) => {
  return axios.post(`${apiBaseUrl}/api/1.0/users`, body);
};

export const activate = (token) => {
  return axios.post(`${apiBaseUrl}/api/1.0/users/token/` + token);
};

export const loadUsers = (page) => {
  return axios.get(`${apiBaseUrl}/api/1.0/users`, {
    params: { page, size: 3 },
  });
};

export const getUserById = (id) => {
  return axios.get(`${apiBaseUrl}/api/1.0/users/${id}`);
};

export const login = (body) => {
  console.log(body);
  console.log(apiBaseUrl);
  return axios.post(`${apiBaseUrl}/api/1.0/auth`, body);
};

export const updateUser = (id, body) => {
  return axios.put(`${apiBaseUrl}/api/1.0/users/${id}`, body);
};

export const logout = () => {
  return axios.post(`${apiBaseUrl}/api/1.0/logout`);
};

export const deleteUser = (id) => {
  return axios.delete(`${apiBaseUrl}/api/1.0/users/${id}`);
};
